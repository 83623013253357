<template>
  <div class="w-full">
    <div class="w-full relative">
      <img
        src="https://quickscreen-assets.s3.us-east-2.amazonaws.com/img/results-page_transparent_opacity.png"
        alt=""
      />
      <div
        class="absolute top-0 left-0 right-0 bottom-0 text-center flex flex-col justify-center items-center pb-24"
      >
        <p
          class="w-full text-white font-bold text-3xl sm:text-5xl p-2 pt-12 font-secondary"
        >
          {{ language.yourResults }}
        </p>
        <div
          class="w-28 h-28 border-4 bg-white border-primary rounded-full flex flex-col justify-center items-center font-secondary flex-shrink-0"
        >
          <h1 class="text-4xl font-bold">{{ eligiblePrograms.length }}</h1>
          <p class="text-sm font-bold lowercase">{{ language.programs }}</p>
        </div>
      </div>
    </div>
    <div class="px-2 sm:px-0">
      <p class="w-full text-left my-8 text-sm">
        {{ language.friendlyAdvocatiaRepContact }}
      </p>
      <FullContactForm />
      <TextField
        label="When is the best time to contact you?"
        :placeholder="language.enterTimeOfDay"
        :modelValue="contactTime"
        @textChange="setContactTime"
        :labelClasses="'mt-6 text-sm mb-2'"
        :inputClasses="'bg-gray-50 rounded px-4 py-2 border-1 text-sm'"
        :focusClasses="'rounded px-4 py-2 border-1 border-secondary bg-secondary-light text-sm'"
      />
    </div>
    <Terms @termsChecked="enableStartEnrollment" />
    <div style="margin-top: 20px" v-if="phoneOrEmailError">
      <Alert
        v-bind:errorMessage="phoneOrEmailMessage"
        @close="togglePhoneEmailError"
      />
    </div>
    <Actions
      :enableEnrollment="getTermsAcknowledgement()"
      @previous="previousStep()"
      @next="startEnrollment"
      class="mt-4"
    >
      <template #previous>
        <span>{{ language.back }}</span>
      </template>
      <template #next>
        <span>{{ language.submit }}</span>
      </template>
    </Actions>
    <h2 class="w-full text-center text-sm my-8">
      {{ language.basedOnYourAnswersYouQualifyFor }}
    </h2>
    <div
      class="flex justify-start items-center px-4 py-2 my-1 rounded-md border shadow-sm bg-white w-full"
      v-for="(program, index) in eligiblePrograms"
      :key="index"
    >
      <div
        class="flex justify-center items-center w-6 h-6 mr-4 border rounded-full border-primary bg-primary-light text-primary"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
          class="ai ai-Check"
        >
          <path d="M4 12l6 6L20 6" />
        </svg>
      </div>
      <p class="text-sm">{{ program.programName }}</p>
    </div>
  </div>
</template>

<script>
import useQuickscreen from "../../../../composables/useQuickscreen";
import useBenefitRecord from "../../../../composables/useBenefitRecord";
import useLanguage from "../../../../composables/useLanguage";
import useApp from "../../../../composables/useApp";
import Actions from "../../ui/Actions.vue";
import FullContactForm from "../../ui/FullContactForm.vue";
import TextField from "../../../common/ui/TextField.vue";
import { onMounted, ref } from "vue";
import Terms from "../../../../views/screen/Terms.vue";
import useTerms from "../../../../composables/useTerms";
import { useGtag } from "vue-gtag-next";
import Alert from "../../..//common/ui/Alert.vue";

export default {
  components: {
    FullContactForm,
    Actions,
    TextField,
    Terms,
    Alert,
  },
  setup() {
    const {
      eligiblePrograms,
      saveQuickscreen,
      previousStep,
      nextStep,
      decrementListIndex,
    } = useQuickscreen();
    const { query } = useGtag();
    const { setLoading } = useApp();
    const { email, phone, addNote, saveBenefitRecord } = useBenefitRecord();
    const { language } = useLanguage();
    const { getTermsAcknowledgement } = useTerms();

    const phoneOrEmailError = ref(false);
    const phoneOrEmailMessage =
      "Please enter a phone number or email to continue.";

    const contactTime = ref("");
    const setContactTime = (newContactTime) => {
      contactTime.value = newContactTime;
    };

    const mapEligibility = (elig) => {
      switch (elig) {
        case "Medicaid":
          return "Med";
        case "Charity":
          return "Char";
        case "CashFood":
          return "CF";
        case "Alternative":
          return "Alt";
        case "Marketplace":
          return "MP";
        case "MedicaidSupplemental":
          return "MedSup";
        case "VictimOfCrime":
          return "VoC";
        case "MedicaidAlternative":
          return "MedAlt";
        case "Organization":
          return "Org";
        case "SupplementalSecurity":
          return "SSI";
        case "MedicareSupplemental":
          return "McareSup";
        case "Medicare":
          return "MCare";
        default:
          return elig;
      }
      // if (elig === "Medicaid") return "Med";
      // return elig;
    };

    const logEligiblePrograms = (eventName) => {
      if (!eligiblePrograms.value.length) {
        const noResultKey = (eventName += "No results");
        console.log("UPDATED GA EVENT: " + noResultKey);
        query("event", noResultKey, {
          event_category: "No results",
        });
      } else {
        var eligibleProgramsNoteList = eligiblePrograms.value.map(
          (p) => p.programType
        );
        eligibleProgramsNoteList = eligibleProgramsNoteList.map(mapEligibility);
        const delimitedPrograms = eligibleProgramsNoteList.join(" | ");
        const eventKey = (eventName += delimitedPrograms);

        console.log("UPDATED GA EVENT: " + eventKey);
        query("event", eventKey, {
          event_category: delimitedPrograms,
        });
      }
    };

    const getEligibleProgramsNote = () => {
      if (!eligiblePrograms.value.length) {
        return "Quickscreen eligibility results: Not Eligible";
      } else {
        var eligibleProgramsNoteList = eligiblePrograms.value.map(
          (p) => p.programType
        );
        console.log("Note list: " + eligibleProgramsNoteList);
        var eligibleProgramsNote = "Quickscreen eligibility results: ";
        eligibleProgramsNote += eligibleProgramsNoteList.join(" | ");
        console.log(eligibleProgramsNote);
        return eligibleProgramsNote;
      }
    };

    const togglePhoneEmailError = () => {
      console.log("toggling");
      phoneOrEmailError.value = !phoneOrEmailError.value;
    };

    onMounted(() => {
      logEligiblePrograms("display: ");
    });
    // display, submitted: MED
    // display: submit:
    const enableEnrollment = ref(false);
    const enableStartEnrollment = (termsChecked) => {
      enableEnrollment.value = termsChecked;
      console.log("Enable enrollment hit. Value: " + enableEnrollment.value);
    };
    const getEnableEnrollment = () => {
      return enableEnrollment.value;
    };
    const startEnrollment = async () => {
      if (phone.value !== "" || email.value !== "") {
        setLoading(true);
        await saveQuickscreen();
        await saveBenefitRecord();

        // have to do this after posting benefit record so there is a visit on the record
        console.log(
          "eligible programs length: " + eligiblePrograms.value.length
        );
        if (contactTime.value.length) {
          addNote(`Best time to contact: ${contactTime.value}`);
        }

        const eligibleProgramsNote = getEligibleProgramsNote();
        addNote(eligibleProgramsNote);

        logEligiblePrograms("submitted: ");

        console.log("saving benefit record for eligible programs or contact");
        await saveBenefitRecord();

        setLoading(false);
        nextStep();
      } else {
        // enable alert
        phoneOrEmailError.value = true;
      }
    };

    return {
      eligiblePrograms,
      language,
      contactTime,
      startEnrollment,
      previousStep,
      setContactTime,
      decrementListIndex,
      enableStartEnrollment,
      getEnableEnrollment,
      getTermsAcknowledgement,
      logEligiblePrograms,
      phoneOrEmailError,
      phoneOrEmailMessage,
      togglePhoneEmailError,
      getEligibleProgramsNote,
    };
  },
};
</script>

<style></style>
