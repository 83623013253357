<template>
  <FormWrapper>
    <TextField
      label="First name"
      :placeholder="language.firstName"
      :modelValue="firstName === 'New' ? '' : firstName"
      @textChange="setFirstName"
      :inputClasses="'bg-gray-50 rounded px-4 py-2 border-1 text-sm'"
      :focusClasses="'rounded px-4 py-2 border-1 border-secondary bg-secondary-light text-sm'"
    />
    <TextField
      label="Last name"
      :placeholder="language.lastName"
      :modelValue="lastName === 'Quickscreen' ? '' : lastName"
      @textChange="setLastName"
      :inputClasses="'bg-gray-50 rounded px-4 py-2 border-1 text-sm'"
      :focusClasses="'rounded px-4 py-2 border-1 border-secondary bg-secondary-light text-sm'"
    />
    <TextField
      label="Email"
      :placeholder="language.enterEmail"
      :modelValue="email"
      @textChange="setEmail"
      :inputClasses="'bg-gray-50 rounded px-4 py-2 border-1 text-sm'"
      :focusClasses="'rounded px-4 py-2 border-1 border-secondary bg-secondary-light text-sm'"
      
    />
    <TextField
      label="Phone"
      :placeholder="language.enterPhone"
      :mask="'(###) ###-####'"
      :pattern="'[0-9]*'"
      :modelValue="phone"
      @textChange="setPhone"
      :inputClasses="'bg-gray-50 rounded px-4 py-2 border-1 text-sm'"
      :focusClasses="'rounded px-4 py-2 border-1 border-secondary bg-secondary-light text-sm'"
      
    />
  </FormWrapper>
</template>

<script>
import useBenefitRecord from "../../../composables/useBenefitRecord";
import FormWrapper from "../../common/ui/FormWrapper.vue";
import TextField from "../../common/ui/TextField.vue";
import useLanguage from "../../../composables/useLanguage";
export default {
  components: {
    FormWrapper,
    TextField,
  },
  setup() {
    const { language } = useLanguage();

    const {
      firstName,
      lastName,
      email,
      phone,
      setFirstName,
      setLastName,
      setEmail,
      setPhone,
    } = useBenefitRecord();

    return {
      firstName,
      lastName,
      email,
      phone,
      setFirstName,
      setLastName,
      setEmail,
      setPhone,
      language,
    };
  },
};
</script>

<style></style>
