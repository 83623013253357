<template>
  <div class="grid sm:grid-cols-2 gap-x-4 gap-y-6" :class="wrapperClasses">
    <slot> </slot>
  </div>
</template>

<script>
export default {
  props: {
    wrapperClasses: {
      type: String,
      required: false,
      default: "",
    },
  },
};
</script>

<style></style>
